import  * as React from "react";
import {
  Contact,
  FAQ,
  Figium,
  FirstProject,
  GFCDescription,
  Hero,
  RoadMap,
  BrightFuture,
  Join,
  VcSale,
} from "components";
import Main from "layouts/Main";

import "styles/globals.css";
// @ts-ignore
import * as styles from "styles/index.module.css";

const Home: React.FC = () => (
  <Main>
    <main className={styles.main}>
      <Hero />
      <VcSale />
      <GFCDescription />
      <FirstProject />
      <Figium />
      <BrightFuture />
      <RoadMap />
      {/* <Partners /> */}
      {/* <Team /> */}
      <FAQ />
      <Join />
      <Contact />
    </main>
  </Main>
);

export default Home;
